/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Nombre from '../assets/img/NombreNew.svg'
//import { APIRequest } from "../utils/APIRequest";

import share from '../assets/img/share.svg'
import Cerrar from '../assets/img/Cerrar.svg'

import jwt_decode from 'jwt-decode'
import { config } from '../config'

export const Popup = ({
	setActive,
	setCatalogoActive,
	setCatalogoTradicionalActive,
	setOfertaactive,
	setOfertaactiveTrad
}) => {
	const navigate = useNavigate()
	const [canal, setCanal] = useState(1)
	const [selected, setSelected] = useState(false)

	const refPopup = useRef(null)
	useEffect(() => {
		document.addEventListener('click', closeFilter, true)
	}, [])

	const closeFilter = (e) => {
		if (!refPopup.current.contains(e.target)) {
			setActive(false)
		}
	}

	return (
		<div className='popupWrapper'>
			<div className='popup' ref={refPopup}>
				<div className='close_icon' onClick={(e) => setActive(false)}>
					<img src={Cerrar} alt='' />
				</div>
				<img src={Nombre} alt='' />
				<p>Selecciona el canal y modalidad para crear la promoción</p>
				<b>
					<img src={share} alt='' />
					Canal
				</b>

				<div className='checkbox_container'>
					<div className='left_side'>
						<label htmlFor=''>
							<input
								type='radio'
								name='popradio'
								onChange={(e) => {
									setCanal(1)
								}}
								// defaultChecked
							/>
							<h1>Moderno</h1>
						</label>
					</div>
					<div className='right_side'>
						<label htmlFor=''>
							<input
								type='radio'
								name='popradio'
								onChange={(e) => {
									setCanal(2)
								}}
							/>
							<h1>Tradicional & Food Service</h1>
						</label>
					</div>
				</div>

				<div className='checkbox_container'>
					<div className='left_side'>
						<button
							onClick={
								(e) => {
									setActive(false)
									if (canal == 1) {
										// 1 == moderno
										setCatalogoActive(true)
									}
									if (canal == 2) {
										setCatalogoTradicionalActive(true)
									}
								}
								//handleNewCatalogo
							}
						>
							Catálogos
						</button>
						<p>Creación de Catálogo con resultados de Optimización</p>
					</div>
					<div className='right_side'>
						<button
							onClick={(e) => {
								setActive(false)
								if(canal == 1){
									setOfertaactive(true)
								}

								if(canal == 2){
									setActive(false)
									// navigate('/OfertaTradicional')
									setOfertaactiveTrad(true)
								}
							}}
						>Ofertas</button>
						<p>Creación de Ofertas con carga Manual</p>
					</div>
				</div>
				<div className='button_wrapper'>
					<a href='#' onClick={() => setActive(false)}>
						Volver al Inicio
					</a>
				</div>
			</div>
		</div>
	)
}
