import { useEffect, useState } from "react";

export const OfertaTradCheckBox = ({Each, offices, isCheckedOffice,
    isCheckedFather, setOficinasCheck, oficinasCheck, setFirstFilter
}) => { // Each es zona

    const [checkDaddy, setCheckDaddy] = useState(false)

    useEffect(() => {
        setCheckDaddy(isCheckedFather(Each))
    }, [])

    useEffect(() => {
        setCheckDaddy(isCheckedFather(Each))
    }, [oficinasCheck])

    return (
        <li>
													<div className="checkbox_flex">
													<input
														type='checkbox'
														
														id={Each.replace(" ", "")}

														// onChange={() => handleCheckboxChange(Each)}
                                                        onClick={(e) => {
															// alert(e.target.checked)
															// alert(Each+ " Seleccionado")
                                                            if(e.target.checked){
                                                                // alert(Each+ " Seleccionado")
                                                                setCheckDaddy(true)
                                                                if(Each !== "Nacional"){
																// alert(Each)
                                                                // Filtrar las oficinas en 'offices' que no tengan 'group' igual a 'Each'
                                                                const oficinasFiltradas = offices.filter(element => element.group_name === Each);

                                                                // Concatenar las oficinas filtradas con las existentes en 'oficinasCheck'
                                                                const nuevasOficinasCheck = oficinasCheck.concat(oficinasFiltradas);

                                                                // Eliminar duplicados en 'nuevasOficinasCheck' si es necesario
                                                                const oficinasCheckSinDuplicados = Array.from(new Set(nuevasOficinasCheck));

                                                                // Actualizar el estado 'oficinasCheck' con las oficinas filtradas y sin duplicados
                                                                setOficinasCheck(oficinasCheckSinDuplicados);
                                                                }else{
                                                                    setOficinasCheck(offices)
                                                                }

                                                            }else{
                                                                // alert("NO ESTÁ CHECKEADO")
                                                                if(Each !== "Nacional"){
                                                                    setOficinasCheck(
                                                                        oficinasCheck.filter(
                                                                            (of) => of.group_name !== Each
                                                                        )
                                                                    )
                                                                }else{
                                                                    setOficinasCheck([])
                                                                }
                                                                setCheckDaddy(false)
                                                            }
                                                        }}
														//checked={checkDaddy}
														defaultChecked={isCheckedFather(Each)}
														
													/>
													<label
														htmlFor={Each.replace(" ", "")}
														
													></label>
													<p>{Each}</p>
													</div>

						{(Each != "Nacional") && offices
						.filter(function (element) {
							return element.group_name == Each
						})
						.map((EachArr) => (
													<>
														<div className="checkbox_flex checkbox_flex_inner">
														<input
														type='checkbox'
														name='oficinas[]'
														id={EachArr.office_name.replace(" ","")}
														onChange={(e) => {
															if (e.target.checked) {
																setOficinasCheck([
																	...oficinasCheck,
																	{
																		id: EachArr.id,
																		office_name: EachArr.office_name,
																		group_name: EachArr.group_name,
																		office_code: EachArr.office_code,
																	},
																])
																
																console.log('Agregando')
																console.log(oficinasCheck)
															} else {
																// remove from list
																setOficinasCheck(
																	oficinasCheck.filter(
																		(of) => of.id !== EachArr.id
																	)
																)
																setFirstFilter(
																	'Seleccione Oficina(s) Total: ' +
																		String(oficinasCheck.length - 1)
																)
																console.log('Quitando')
																console.log(oficinasCheck)
															}
                                                            setCheckDaddy(isCheckedFather(Each))
															// document.getElementById(Each.replace(" ","")).checked = isCheckedFather(Each);
														}}
                                                        checked={isCheckedOffice(EachArr.id)}
														defaultChecked={isCheckedOffice(EachArr.id)}
													/>
													<label
														htmlFor={`${EachArr.office_name.replace(" ","")}`}
														onClick={(e) => {
															setFirstFilter(EachArr.office_name)
														}}
													></label>
													<p>{EachArr.office_name}</p>
														</div>
													</>
													))}
												</li>
    );
};