import React, { useState } from 'react'
import { Navbar } from './Navbar'
import logotwo from '..//assets/img/PFAlimentos.png'
import icon1 from '..//assets/img/Icono (1).svg'
//import filter from "..//assets/img/filter.svg";
import img1 from '../assets/img/Mayorista.png'
import img2 from '../assets/img/Unimarc.png'
import Jumbo from '../assets/img/Jumbo.svg'
import Líder from '../assets/img/Lider.png'
//import Lider from "../assets/img/Lider.png";
import Santa from '../assets/img/Santa Isabel.svg'
import Tottus from '../assets/img/Tottus.svg'
import hostorial from '../assets/img/hostorial.svg'
import '../assets/css/home.css'
import { Footer } from './Footer'
import { ActiviyBox } from './ActiviyBox'
import { Popup } from './Popup'
import { Oferta } from './newPFOffer/Oferta'
import { Oferta as OfertaTrad} from './newPFOfferTrad/Oferta'
import { Catalogo } from './Catalogo'
import { CatalogoTradicional } from './CatalogoTradicional'
import { TottusPop } from './Tottus'
//import { JumboPop } from "./JumboPop";
import repores from '../assets/img/repores.svg'
import dropdown_style from '../assets/img/dropdown_style.svg'
import { FilterArea } from './FilterArea'
import { useEffect } from 'react'
import { JumboPopNew } from './JumboPopNew'
import { config } from '../config'
import { CodigoPop } from './CodigoPop'
import { Loader } from './Loader'

function getPromotions() {
	const session_token = localStorage.getItem('session')
	//console.log(session_token);
	//if there is a session token stored
	if (session_token) {
		return fetch(config.apiUrl + `/promotion/listall`, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
		})
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}

	//there are no session token stored
	return {}
}

function getPromotionsOffer() {
	const session_token = localStorage.getItem('session')
	//console.log(session_token);
	//if there is a session token stored
	if (session_token) {
		return fetch(config.apiUrlOferta + `/offer/listall`, {
			//mode: 'no-cors',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + session_token,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': true,
			},
		})
			.then((response) => {
				//console.log(response.ok)
				return response.json()
			})
			.then((response) => {
				//console.log(response)
				return response
			})
	}

	//there are no session token stored
	return {}
}

export const Home = () => {
	const [active, setActive] = useState(false)
	const [Ofertaactive, setOfertaactive] = useState(false)
	const [OfertaactiveTrad, setOfertaactiveTrad] = useState(false)
	const [Catalogoactive, setCatalogoActive] = useState(false)
	const [CatalogoTradicionalactive, setCatalogoTradicionalActive] = useState(false)
	const [Jumboactive, setJumboactive] = useState(false)
	const [Activityactive, setActivityactive] = useState(false)
	const [Tuttosactive, setTuttosactive] = useState(false)
	const [dropdownSep, setdropdownSep] = useState(false)
	const [CodigoPop, setCodigoPop] = useState(false)
	const [firstcollection, setfirstCollection] = useState([])
	const [secondcollection, setsecondcollection] = useState([])
	const [thirdcollection, setthirdcollection] = useState([])
	const [fourthcollection, setfourthcollection] = useState([])
	const [dataPromotions, setDataPromotions] = useState(null)
	const [dataPromotionsPhase1, setDataPromotionsPhase1] = useState(null)
	const [dataPromotionsPhase2, setDataPromotionsPhase2] = useState(null)
	const [dataPromotionsPhase3, setDataPromotionsPhase3] = useState(null)
	const [dataPromotionsPhase4, setDataPromotionsPhase4] = useState(null)
	const [dataPromotionsPhase2_toReview, setDataPromotionsPhase2_toReview] =
		useState(null)
	const [dataPromotionsPhase1_all, setDataPromotionsPhase1_all] = useState(null)
	const [dataPromotionsPhase2_all, setDataPromotionsPhase2_all] = useState(null)
	const [dataPromotionsPhase3_all, setDataPromotionsPhase3_all] = useState(null)
	const [dataPromotionsPhase4_all, setDataPromotionsPhase4_all] = useState(null)
	const [loader, setloader] = useState(true)
	const [dataPromotionsOffer, setDataPromotionsOffer] = useState(null)
	const [dataPromotionsCatalogs, setDataPromotionsCatalogs] = useState(null)

	const returnColorState = (state_phase_str) => {
		return '#13acaa'
	}
	const returnLabelDate = (month, end_sellout) => {
		if (!end_sellout) end_sellout = 'SIN AÑO AGREGADO'
		const text_date = month.slice(0, 3) + ' ' + end_sellout
		return text_date.toUpperCase()
	}

	const getNumberMonth = (mes) => {
		const mesEnMinusculas = mes.toLowerCase();

		switch (mesEnMinusculas) {
			case 'enero':
				return 1;
			case 'febrero':
				return 2;
			case 'marzo':
				return 3;
			case 'abril':
				return 4;
			case 'mayo':
				return 5;
			case 'junio':
				return 6;
			case 'julio':
				return 7;
			case 'agosto':
				return 8;
			case 'septiembre':
				return 9;
			case 'octubre':
				return 10;
			case 'noviembre':
				return 11;
			case 'diciembre':
				return 12;
			default:
			// Manejar un mes no válido
				return -1;
		}
	}

	useEffect(() => {
		//setData({promotions: window.catalog_categories})

		getPromotionsOffer().then(result => {
			console.log(result)
			//Procesar info y dejarla tal como en catalogo aunque falte información destacando que la info es de oferta
			let resultRows = []
			result.forEach((element) => {
				
				let newElement = {
					"ben_opt": element.ben_opt,
					"ben_prop": element.ben_strat,
					"color": element.color,
					"customer": {
						"active": 1,
						"channel": {
							"channel": "15",
							"description_channel": "Moderno"
						},
						"name_customer": element.customer
					},
					"date_created": element.created_at,
					"end_sellin": element.end_sellin,
					"end_sellout": element.end_sellout,
					"id": element.offer_id * 1000, // Dandole prioridad a las ofertas
					"promotion_id": element.offer_id,
					"last_update": element.updated_at,
					"linea": "OFERTA",
					"month_promotion": getNumberMonth(element.promotion_month),
					"month_promotion_str": element.promotion_month,
					"name_promotion": element.promotion_name,
					"percent_activation": element.activation,
					"promotional_state": {
						"phase_str": element.phase,
						"promotional_state_id": -100,
						"state_phase_str": element.state
					},
					"res_op_opt": element.ro_opt,
					"res_op_prop": element.ro_prop,
					"res_op_act": element.ro_act,
					"start_sellin": element.start_sellin,
					"start_sellout": element.start_sellout,
					"type_promotion": {
						"type_promotion": "OFERTA",
						"type_promotion_id": 3,
						"show_promo": element.show_promo
					},
					"year_promotion_str": element.promotion_year,
					"posible_moves": element.posible_moves
				}
				resultRows.push(newElement)
			})
			console.log("Posibles Movimientos Origen::::: ")
			console.log(resultRows)
			setDataPromotionsOffer(resultRows)
		})

		getPromotions().then((result) => {
			setDataPromotionsCatalogs(result)
			
		})
		
	}, [])

	useEffect(() => {
		
		if (dataPromotionsCatalogs && dataPromotionsOffer) {
			let result = [...dataPromotionsCatalogs, ...dataPromotionsOffer] // Esto es la combinación de las promociones de oferta y catalogo

			let promotionsPhase1 = result.filter(function (element) {
				return element.promotional_state.phase_str == 'INICIAL'
			})
			let promotionsPhase2 = result.filter(function (element) {
				return (
					element.promotional_state.phase_str == 'COLABORATIVA' &&
					['Descartada', 'En edición', 'Rechazada', 'Condicional'].indexOf(
						element.promotional_state.state_phase_str
					) > -1
				)
			})
			let promotionsPhase3 = result.filter(function (element) {
				return element.promotional_state.phase_str == 'NEGOCIACIÓN'
			})
			let promotionsPhase4 = result.filter(function (element) {
				return element.promotional_state.phase_str == 'EJECUCIÓN'
			})
			let promotionsPhase2_toReview = result.filter(function (element) {
				return (
					element.promotional_state.phase_str == 'COLABORATIVA' &&
					['En revisión'].indexOf(
						element.promotional_state.state_phase_str
					) > -1
				)
			})
			/* // ordenamiento por id
			promotionsPhase1 = promotionsPhase1.sort((a, b) => b.id - a.id)
			promotionsPhase2 = promotionsPhase2.sort((a, b) => b.id - a.id)
			promotionsPhase3 = promotionsPhase3.sort((a, b) => b.id - a.id)
			promotionsPhase4 = promotionsPhase4.sort((a, b) => b.id - a.id) */
			promotionsPhase1 = promotionsPhase1.sort((a, b) => new Date(b.last_update.replace('|', '').replace(/\//g, ' ')) - new Date(a.last_update.replace('|', '').replace(/\//g, ' ')));
			promotionsPhase2 = promotionsPhase2.sort((a, b) => new Date(b.last_update.replace('|', '').replace(/\//g, ' ')) - new Date(a.last_update.replace('|', '').replace(/\//g, ' ')));
			promotionsPhase3 = promotionsPhase3.sort((a, b) => new Date(b.last_update.replace('|', '').replace(/\//g, ' ')) - new Date(a.last_update.replace('|', '').replace(/\//g, ' ')));
			promotionsPhase4 = promotionsPhase4.sort((a, b) => new Date(b.last_update.replace('|', '').replace(/\//g, ' ')) - new Date(a.last_update.replace('|', '').replace(/\//g, ' ')));
			
			setDataPromotionsPhase1(promotionsPhase1)
			setDataPromotionsPhase2(promotionsPhase2)
			setDataPromotionsPhase3(promotionsPhase3)
			setDataPromotionsPhase4(promotionsPhase4)
			setDataPromotionsPhase2_toReview(promotionsPhase2_toReview)
			setDataPromotionsPhase1_all(promotionsPhase1)
			setDataPromotionsPhase2_all(promotionsPhase2)
			setDataPromotionsPhase3_all(promotionsPhase3)
			setDataPromotionsPhase4_all(promotionsPhase4)
			
			setDataPromotions(result) // La combinación de ambos que en este cas

			
			setloader(false)
		}
	}, [dataPromotionsCatalogs, dataPromotionsOffer])
	//console.log(dataPromotions);

	return (
		<div className='Home'>
			{/* {Catalogoactive && <Catalogo setActive={setCatalogoActive} />} */}
			{CatalogoTradicionalactive && (
				<CatalogoTradicional setActive={setCatalogoTradicionalActive} 
				setAtras={setActive} dataPromotions={dataPromotions}/>
			)}
			{/*CatalogoTradicionalactive && <CatalogoTradicional setActive={setCatalogoTradicionalActive} />*/}
			{active && (
				<Popup
					setActive={setActive}
					setCatalogoActive={setCatalogoActive}
					setCatalogoTradicionalActive={setCatalogoTradicionalActive}
					setOfertaactive={setOfertaactive}
					setOfertaactiveTrad={setOfertaactiveTrad}
				/>
			)}
			{Ofertaactive && <Oferta setActive={setOfertaactive} setActiveBack={setActive} />}
			{OfertaactiveTrad && <OfertaTrad setActive={setOfertaactiveTrad} setActiveBack={setActive} 
				dataPromotions={dataPromotions}
			/>}
			{Catalogoactive && <Catalogo setActive={setCatalogoActive} setAtras={setActive}
			dataPromotions={dataPromotions}
			open={true}
			/>}
			{Jumboactive && <JumboPopNew setActive={setJumboactive} />}

			{Activityactive && (
				<JumboPopNew
					setActive={setActivityactive}
					Activityactive={Activityactive}
				/>
			)}

			{Tuttosactive && <TottusPop setActive={setTuttosactive} />}
			{CodigoPop && <CodigoPop setCodigoPop={setCodigoPop} />}

			<Navbar />
			{loader && <Loader message={'Cargando...'}></Loader>}

			<div className='top_area top_area_top'>
				<div className='single_box'>
					<img src={logotwo} alt='' />
					<button onClick={(e) => setActive(true)}>Ingresar Promoción</button>
				</div>
				<div className='green_box'>
					<img src={icon1} alt='' />
					<p>Visibilidad</p>
					<button 
					onClick={() => window.location.href = '/dashboard/optimizacion'}
					style={{ cursor: 'pointer' }}
					>Ver</button>
				</div>

				<div className='green_box'>
					<img src={repores} alt='' />
					<p>Ev. Promociones</p>
					<button 
					onClick={() => window.location.href = '/dashboard/reportes'}
					style={{ cursor: 'pointer' }}
					>Ver</button>
				</div>

				<div className='green_box'>
					<img src={hostorial} alt='' />
					<p>Historial de Promociones</p>
					<button 
					onClick={() => window.location.href = '/dashboard/historial'}
					style={{ cursor: 'pointer' }}
					>Ver</button>
				</div>
			</div>

			<div className='box_big'>
				<h1>Promociones Activas</h1>
				<div className='boxes_wrapper'>
					<div className='box_long'>
						<h1>Propuesta Inicial</h1>
						<div
							className='content_area'
							style={{ position: 'relative', height: '75vh' }}
						>
							<FilterArea
								point={'one'}
								collection={firstcollection}
								setCollection={setfirstCollection}
								dataPromotion={dataPromotionsPhase1}
								setDataPromotions={setDataPromotionsPhase1}
								dataAll={dataPromotionsPhase1_all}
								//onChange={reFilter(firstcollection)}
							/>

							{!dataPromotionsPhase1
								? ''
								: dataPromotionsPhase1.map((EachRow) => (
										<ActiviyBox
											topText={EachRow.promotional_state.state_phase_str.toUpperCase()} //"EN EDICIÓN"
											mainp={EachRow.name_promotion} //"Jumbo Abril 2022"
											bottomp1={EachRow.type_promotion.type_promotion.toUpperCase()} //"CATÁLOGO"
											bottomp2={EachRow.customer.channel.description_channel.toUpperCase()} //"MODERNO"
											catalogo={(e) => setCatalogoActive(true)}
											jumbo={(e) => setJumboactive(false)}
											img={EachRow.customer.name_customer} //{returnLogoCustomer(EachRow.customer.name_customer)}  //{Jumbo}
											Activityactive={EachRow}
											setActivityactive={setActivityactive}
											//onClick={openModal(EachRow)}
											bgColor={returnColorState(
												EachRow.promotional_state.state_phase_str
											)} //"#FF5334"
											date={returnLabelDate(
												EachRow.month_promotion_str,
												EachRow.year_promotion_str
											)} //"Jun.2022"
											percentage={EachRow.percent_activation + '%'}
											date_created={EachRow.last_update}
										/>
								  ))}
						</div>
					</div>

					<div className='box_long'>
						<h1>Propuesta Colaborativa</h1>
						<div
							className='content_area'
							style={{ position: 'relative', height: '75vh' }}
						>
							<FilterArea
								point={'two'}
								collection={secondcollection}
								setCollection={setsecondcollection}
								dataPromotion={dataPromotionsPhase2}
								setDataPromotions={setDataPromotionsPhase2}
								dataAll={dataPromotionsPhase2_all}
							/>
							<div
								className={`dropdown_cover ${dropdownSep && 'active_dropdown'}`}
								onClick={(e) => {
									setdropdownSep(!dropdownSep)
								}}
							>
								{dataPromotionsPhase2_toReview &&
									dataPromotionsPhase2_toReview.length > 0 && (
										<button className='dropdown_style'>
											Pendientes de Aprobación
											<img src={dropdown_style} alt='' />
										</button>
									)}
								{dropdownSep &&
									(!dataPromotionsPhase2_toReview
										? ''
										: dataPromotionsPhase2_toReview.map((EachRow) => (
												<ActiviyBox
													topText={EachRow.promotional_state.state_phase_str.toUpperCase()} //"EN EDICIÓN"
													mainp={EachRow.name_promotion} //"Jumbo Abril 2022"
													bottomp1={EachRow.type_promotion.type_promotion.toUpperCase()} //"CATÁLOGO"
													bottomp2={EachRow.customer.channel.description_channel.toUpperCase()} //"MODERNO"
													catalogo={(e) => setCatalogoActive(true)}
													jumbo={(e) => setJumboactive(false)}
													img={EachRow.customer.name_customer} //{Jumbo}
													Activityactive={EachRow}
													setActivityactive={setActivityactive}
													//onClick={openModal(EachRow)}
													bgColor={returnColorState(
														EachRow.promotional_state.state_phase_str
													)} //"#FF5334"
													date={returnLabelDate(
														EachRow.month_promotion_str,
														EachRow.year_promotion_str
													)} //"Jun.2022"
													percentage={EachRow.percent_activation + '%'}
													date_created={EachRow.last_update}
												/>
										  )))}
							</div>
							{!dataPromotionsPhase2
								? ''
								: dataPromotionsPhase2.map((EachRow) => (
										<ActiviyBox
											topText={EachRow.promotional_state.state_phase_str.toUpperCase()} //"EN EDICIÓN"
											mainp={EachRow.name_promotion} //"Jumbo Abril 2022"
											bottomp1={EachRow.type_promotion.type_promotion.toUpperCase()} //"CATÁLOGO"
											bottomp2={EachRow.customer.channel.description_channel.toUpperCase()} //"MODERNO"
											catalogo={(e) => setCatalogoActive(true)}
											jumbo={(e) => setJumboactive(false)}
											img={EachRow.customer.name_customer} //{Jumbo}
											Activityactive={EachRow}
											setActivityactive={setActivityactive}
											//onClick={openModal(EachRow)}
											bgColor={returnColorState(
												EachRow.promotional_state.state_phase_str
											)} //"#FF5334"
											date={returnLabelDate(
												EachRow.month_promotion_str,
												EachRow.year_promotion_str
											)} //"Jun.2022"
											percentage={EachRow.percent_activation + '%'}
											date_created={EachRow.last_update}
											linea={EachRow.linea}
										/>
								  ))}
						</div>
					</div>

					<div className='box_long'>
						<h1>Negociación</h1>
						<div
							className='content_area'
							style={{ position: 'relative', height: '75vh' }}
						>
							<FilterArea
								point={'three'}
								collection={thirdcollection}
								setCollection={setthirdcollection}
								dataPromotion={dataPromotionsPhase3}
								setDataPromotions={setDataPromotionsPhase3}
								dataAll={dataPromotionsPhase3_all}
							/>
							{!dataPromotionsPhase3
								? ''
								: dataPromotionsPhase3.map((EachRow) => (
										<ActiviyBox
											topText={EachRow.promotional_state.state_phase_str.toUpperCase()} //"EN EDICIÓN"
											mainp={EachRow.name_promotion} //"Jumbo Abril 2022"
											bottomp1={EachRow.type_promotion.type_promotion.toUpperCase()} //"CATÁLOGO"
											bottomp2={EachRow.customer.channel.description_channel.toUpperCase()} //"MODERNO"
											catalogo={(e) => setCatalogoActive(true)}
											jumbo={(e) => setJumboactive(false)}
											img={EachRow.customer.name_customer} //{Jumbo}
											Activityactive={EachRow}
											setActivityactive={setActivityactive}
											//onClick={openModal(EachRow)}
											bgColor={returnColorState(
												EachRow.promotional_state.state_phase_str
											)} //"#FF5334"
											date={returnLabelDate(
												EachRow.month_promotion_str,
												EachRow.year_promotion_str
											)} //"Jun.2022"
											percentage={EachRow.percent_activation + '%'}
											date_created={EachRow.last_update}
										/>
								  ))}
						</div>
					</div>

					<div className='box_long'>
						<h1>Ejecución</h1>
						<div className='content_area' style={{ position: 'relative', height: '75vh' }}>
							<FilterArea
								point={'four'}
								collection={fourthcollection}
								setCollection={setfourthcollection}
								dataPromotion={dataPromotionsPhase4}
								setDataPromotions={setDataPromotionsPhase4}
								dataAll={dataPromotionsPhase4_all}
							/>

							{!dataPromotionsPhase4
								? ''
								: dataPromotionsPhase4.map((EachRow) => (
										<ActiviyBox
											topText={EachRow.promotional_state.state_phase_str.toUpperCase()} //"EN EDICIÓN"
											mainp={EachRow.name_promotion} //"Jumbo Abril 2022"
											bottomp1={EachRow.type_promotion.type_promotion.toUpperCase()} //"CATÁLOGO"
											bottomp2={EachRow.customer.channel.description_channel.toUpperCase()} //"MODERNO"
											catalogo={(e) => setCatalogoActive(true)}
											jumbo={(e) => setJumboactive(false)}
											img={EachRow.customer.name_customer} //{Jumbo}
											Activityactive={EachRow}
											setActivityactive={setActivityactive}
											//onClick={openModal(EachRow)}
											bgColor={returnColorState(
												EachRow.promotional_state.state_phase_str
											)} //"#FF5334"
											date={returnLabelDate(
												EachRow.month_promotion_str,
												EachRow.year_promotion_str
											)} //"Jun.2022"
											percentage={EachRow.percent_activation + '%'}
											date_created={EachRow.last_update}
										/>
								  ))}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
