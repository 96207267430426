import React, {useState} from 'react'
import '../../assets/css/newPFOffer/IngresoProductTable.css'
import {ProductTableLinea} from './ProductTableLinea'
import {ProductTableFamilia} from './ProductTableFamilia'
export const ProductTableRow = ({setdeletestate, setModeloPopupState, idOferta, offers, familia, 
	groupedRows, setProduct, SecondFilter, setRowChange, setDeleteRow, mgPvp}) => {
	const [ProductTableFamiliaState, setProductTableFamiliaState] = useState(true)
	// const [ProductTableFamiliaInnerState, setProductTableFamiliaInnerState] = useState(null)
	const [ProductTableInnerState, setProductTableInnerState] = useState(true)

	const subfamilias = [];
	const subfamiliasSet = new Set(); // Utilizamos un Set para almacenar valores únicos
	console.log("Ofertas filtradas:::: ")
	console.log(offers);
	offers.map((offer) => {
		subfamiliasSet.add(offer.subcategory_name);
	});

	// Convertimos el Set a un array
	subfamilias.push(...subfamiliasSet);
	console.log("SubFamilias finales::: ")
	console.log(subfamilias);
	console.log("GROPED ROWS")
	console.log(groupedRows)

	return (
		<>
			<ProductTableLinea // Fila de familia, ingresar data filtrada por línea
				setProductTableFamiliaState={setProductTableFamiliaState}
				ProductTableFamiliaState={ProductTableFamiliaState}
				// setProductTableFamiliaInnerState={setProductTableFamiliaInnerState}
				setProductTableInnerState={setProductTableInnerState}
				familia = {familia}
				data={groupedRows[familia]}
			/>
			{ProductTableFamiliaState && ( // Uno por cada subfamilia

				subfamilias.map((subfamilia) => {
					const ofertasFiltradas = offers.filter((offer) => offer.subcategory_name === subfamilia);
					console.log("FAMILIA Y SUBFAMILIA FILTRADA PARA MOSTRAR FILA: "+familia+" "+subfamilia+" ")
					return (
						<ProductTableFamilia
						// setProductTableFamiliaInnerState={setProductTableFamiliaInnerState}
						// ProductTableFamiliaInnerState={ProductTableFamiliaInnerState}
						setdeletestate={setdeletestate}
						setModeloPopupState={setModeloPopupState}
						subfamilia = {subfamilia}
						offers = {ofertasFiltradas}
						data={groupedRows[familia].families[subfamilia]}
						setProduct={setProduct}
						SecondFilter={SecondFilter} // Se envía Filtro
						setRowChange={setRowChange}
						setDeleteRow={setDeleteRow}
						mgPvp={mgPvp}
					/>
					)
				}
					
					)
				)
			}

			
		</>
	)
}
