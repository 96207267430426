import React from "react";

export const SwitchButton = ({ setActive, active}) => {

  
  return (
    <div
      className={`SwitchButton ${active && "active"}`}
      onClick={(e) => {
        
        setActive(!active);
      }}
    >
      <span className="SwitchButtonBall"></span>
    </div>
  );
};
