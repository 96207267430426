import React, {useState} from 'react'
import '../../assets/css/newPFOffer/IngresoProductTable.css'
import c from '../../assets/img/newPFOffer/c.svg'
import o from '../../assets/img/newPFOffer/o.svg'
import greenarrow from '../../assets/img/newPFOffer/green-arrow.svg'
import closing from '../../assets/img/newPFOffer/close.svg'
import { ToolTipCustomList } from '../ToolTipCustomList'
import orangearrow from '../../assets/img/orange_down.svg'

function contarOcurrencias(jsonData) {
	let vacias = 0;
	let noVacias = 0;
  
	for (const key in jsonData) {
	  if (jsonData.hasOwnProperty(key)) {
		const valor = jsonData[key];
  
		if (valor === "" || valor === "-" || valor === "0") {
		  vacias++;
		} else {
		  noVacias++;
		}
	  }
	}
  
	return { vacias, noVacias };
  }

export const ProductTableInner = ({data}) => {
	const [MouseDownState, setMouseDownState] = useState(null)
	const resultado = contarOcurrencias(data.promotional_variables_json);
	return (
		<tr className='ProductTableInner familia1'>
			<td></td>
			<td className='border-left'></td>
			<td>
				<div className='border-center'></div>
			</td>
			<td>
				<div style={{paddingTop: 3}}>
					{data.type === "catalog" ? <img src={c} alt='' /> : <img src={o} alt='' />}
				</div>
			</td>
			<td colSpan={2} className='border-gray-custom-blue'>
				<div className='display_flex  gray' style={{cursor: 'pointer', position: 'relative',
					fontSize: '9px'}}
					onMouseUp={(e) => {
						const Tooltip = e.target.querySelector('.ToolTipCustom')
						if(Tooltip){
							// Tooltip.classList.remove('show')
							Tooltip.style.display = 'none'
						}
						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						console.log("BOTON TOOLTIP::: ")
						console.log(e.target);

						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							const Tooltip = e.target.querySelector('.ToolTipCustom')
							// Tooltip.classList.add('show')
							Tooltip.style.display = 'block'
							console.log("Apareciendo Tooltip:....")
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					onMouseLeave={(e) => {
						const Tooltip = e.currentTarget.querySelector('.ToolTipCustom');
						if (Tooltip) {
						  Tooltip.style.display = 'none';
						}
					  }}
					>
					<ToolTipCustomList 
					// value={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
					list={["SI: "+data.tooltip_sellin, "SO: "+data.tooltip_sellout]}
					width={'160px'} 
					showOption='show'
					/>
					{data.promotion_name}
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p style={{fontSize: '11px'}}>{data.curr_vol}</p>
				</div>
			</td>
			<td>
				<div className='display_flex green'>
					<p style={{fontSize: '11px'}}>{data.opt_vol}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p style={{fontSize: '11px'}}>{data.strat_vol}</p>
				</div>
			</td>
			<td className='border-gray-custom-blue'>
				<div className={`display_flex   
				${parseFloat(data.var.replace("%","").replace(",",".")) >= 0 ? 'green' : 'orange'}`}>
				{parseFloat(data.var.replace("%","").replace(",",".")) >=
					 0 ? 
						<img src={greenarrow} alt='' />
						:
						<img src={orangearrow} alt='' />}
					<p style={{fontSize: '11px'}}>{data.var}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p style={{fontSize: '11px'}}>{data.base_price}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p style={{fontSize: '11px'}}>{data.curr_price}</p>
				</div>
			</td>
			<td>
				<div className='display_flex green'>
					<p style={{fontSize: '11px'}}>{data.opt_price}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p style={{fontSize: '11px'}}>{data.strat_price}</p>
				</div>
			</td>
			<td className='border-gray-custom-blue'>
				<div className='display_flex gray'>
					<p style={{fontSize: '11px'}}>{data.discount}</p>
				</div>
			</td>

			<td>
				<div className={`display_flex   
				${parseFloat(data.__var_eb) >= 0 ? 'green' : 'orange'}`}>
				{parseFloat(data.__var_eb) >=
					 0 ? 
						<img src={greenarrow} alt='' />
						:
						<img src={orangearrow} alt='' />}
					<p className='ten' style={{fontSize: '11px'}}>{data.var_eb}</p>
				</div>
			</td>
			<td>
				<div className={`display_flex   
				${parseFloat(data.__var_ob) >= 0 ? 'green' : 'orange'}`}>
				{parseFloat(data.__var_ob) >=
					 0 ? 
						<img src={greenarrow} alt='' />
						:
						<img src={orangearrow} alt='' />}
					<p className='ten' style={{fontSize: '11px'}}>{data.var_ob}</p>
				</div>
			</td>
			<td>
				<div className={`display_flex   
				${parseFloat(data.__strat_ro_pct) >= 0 ? 'green' : 'orange'}`}>
				{parseFloat(data.__strat_ro_pct) >=
					 0 ? 
						<img src={greenarrow} alt='' />
						:
						<img src={orangearrow} alt='' />}
					<p className='ten' style={{fontSize: '11px'}}>{data.strat_ro_pct}</p>
				</div>
			</td>
			<td>
				<div className={`display_flex   
				${parseFloat(data.__incr_ro) >= 0 ? 'green' : 'orange'}`}>
				{parseFloat(data.__incr_ro) >=
					 0 ? 
						<img src={greenarrow} alt='' />
						:
						<img src={orangearrow} alt='' />}
					<p className='ten' style={{fontSize: '11px'}}>{data.incr_ro}</p>
				</div>
			</td>
			<td>
				<div className='display_flex   gray'>
					
					<p className='ten' style={{fontSize: '11px'}}>-</p>
				</div>
			</td>
			<td className='border-gray-custom-blue'>
				<div className='display_flex gray'>
					<p className='ele' style={{fontSize: '11px'}}>{data.elasticity}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<p className='ele' style={{fontSize: '11px'}}>{data.customer_margin}%</p>
				</div>
			</td>
			<td className='border-gray-custom-blue'>
				<div className='display_flex gray'>
					<p className='ele' style={{fontSize: '11px'}}>{data.recommended_pvp}</p>
				</div>
			</td>

			<td>
				<div className='display_flex gray'>
					<a href='#' className='ele' style={{fontSize: '11px'}}
						onClick={() => {
							const text = `Activación: ${data.promotional_variables_json.activation} \nAdherencia: ${data.promotional_variables_json.adherence}\nExhibición: ${data.promotional_variables_json.exhibition}\nInversión Adicional: ${data.promotional_variables_json.aditional_inv}\nMécanica: ${data.promotional_variables_json.mechanic}`;
							alert(text);
						}}
					>
					{resultado.noVacias}/{resultado.vacias + resultado.noVacias} 
					</a>
					{ //<img src={closing} alt='' style={{cursor: 'pointer'}} />
							}
				</div>
			</td>
		</tr>
	)
}
