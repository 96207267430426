import React, {useState} from 'react'
import '../../assets/css/newPFOffer/IngresoProductTable.css'
import Table from 'react-bootstrap/Table'
import filterNew from '../../assets/img/filter_new.svg'
import dropdownprod from '../../assets/img/newPFOffer/dropdown.svg'
import forwardArrow from '../../assets/img/forwardArrow.svg'
import dropdown from '../../assets/img/dropdown.svg'
import tick2 from '../../assets/img/newPFOffer/tick.svg'
import dollar from '../../assets/img/dollar.svg'
import greenarrow from '../../assets/img/newPFOffer/green-arrow.svg'
import closing from '../../assets/img/newPFOffer/close.svg'
import {ProductTableRow} from './ProductTableRow'
import { parse } from 'date-fns'

export const IngresoProductTable = ({setdeletestate, setModeloPopupState, idOferta, offers
, setProduct, setRowChange, setDeleteRow, setMgPvp, mgPvp
}) => {
	const [FirstFilter, setFirstFilter] = useState('Todas')
	const [mgcad, setmgcad] = useState('')
	const [SecondFilter, setSecondFilter] = useState('Todas')
	const [SecondDropdown, setSecondDropdown] = useState(false)
	const [FirstDropdown, setFirstDropdown] = useState(false)


	console.log("Ofertas::::: ")
	console.log(offers);
	const familias = [];
	const shortBrands = []
	const familiasSet = new Set(); // Utilizamos un Set para almacenar valores únicos
	const shorBrandsSet = new Set();
	offers.data_rows.forEach((offer) => {
		familiasSet.add(offer.category_name);
		shorBrandsSet.add(offer.subfamily);
	});

	// Convertimos el Set a un array
	familias.push(...familiasSet);
	shortBrands.push(...shorBrandsSet);
	console.log("Familias finales::: ")
	console.log(familias);

	return (
		<div className='SeguimientoTable VienesasTable IngresoProductTable  SeguimientoTableOfertaNew'>
			<Table>
				<thead>
					<tr>
						<th className='fimila fimiladropdown ' rowSpan={2}>
							<div className='dropdown dropdown_new'>
								<div
									className='head'
									onClick={(e) => {
										setFirstDropdown(!FirstDropdown)
									}}
								>
									<img src={filterNew} alt='' />
									<p>{FirstFilter}</p>
									<img src={dropdown} alt='' />
								</div>
								{FirstDropdown && (
									<>
										<div
											className='off-bg-area'
											onClick={(e) => {
												setFirstDropdown(!FirstDropdown)
											}}
										></div>
										<ul>
											<li>
												<input type='radio' name='second_filter' id='Todas1' />
												<label
													htmlFor='Todas1'
													onClick={(e) => {
														setFirstFilter('Todas')
														setFirstDropdown(!FirstDropdown)
													}}
												></label>
												<p>Todas</p>
											</li>
											{
									familias.map((familia) => {
										return <>
										<li>
												
												<input type='radio' name='second_filter' id={familia} />
												<label
													htmlFor={familia}
													onClick={(e) => {
														setFirstFilter(familia)
														setFirstDropdown(!FirstDropdown)
													}}
												></label>
												<p>{familia}</p>
											</li>
										</>
									})}
											
											
										</ul>
									</>
								)}
							</div>
						</th>
						<th className='fimila border_head_gray fimiladropdown' rowSpan={2}>
							<div
								className='dropdown dropdown_new'
								style={{paddingLeft: 5, paddingRight: 5}}
							>
								<div
									className='head'
									onClick={(e) => {
										setSecondDropdown(!SecondDropdown)
									}}
								>
									<img src={filterNew} alt='' />
									<p>{SecondFilter}</p>
									<img src={dropdown} alt='' />
								</div>
								{SecondDropdown && (
									<>
										<div
											className='off-bg-area'
											onClick={(e) => {
												setSecondDropdown(!SecondDropdown)
											}}
										></div>
										<ul>
											<li>
												<input type='radio' name='second_filter' id='Todas' />
												<label
													htmlFor='Todas'
													onClick={(e) => {
														setSecondFilter('Todas')
														setSecondDropdown(false)
													}}
												></label>
												<p>Todas</p>
											</li>
											{
												shortBrands.map((familia) => {
													return <>
													<li>
															
															<input type='radio' name='second_filter' id={familia} />
															<label
																htmlFor={familia}
																onClick={(e) => {
																	setSecondFilter(familia)
																	setSecondDropdown(false)
																}}
															></label>
															<p>{familia}</p>
														</li>
													</>
												})}
											
											
										</ul>
									</>
								)}
							</div>
						</th>

						<th colSpan={3} className='border_head_gray'>
							<div className='display_flex green'>
								<p>Volumen</p>
							</div>
						</th>
						<th colSpan={3} className='border_head_gray'>
							<div className='display_flex green'>
								<p>Precio</p>
							</div>
						</th>
						<th colSpan={3} className='border_head_gray'>
							<div className='display_flex green'>
								<p>Resultado MM</p>
							</div>
						</th>

						<th colSpan={3} className='border_head_gray'>
							<div className='display_flex blue'>
								<p>Beneficio</p>
							</div>
						</th>

						<th>
							<div className='display_flex blue'>
								<p>Mg.Cad.</p>
							</div>
						</th>
					</tr>

					<tr className='bottom_row_head'>
						<th>
							<div className='head_box'>
								<h1>Act.</h1>
								<p>{offers.header[0] && offers.header[0].curr_vol}</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Opt.</h1>
								<p>{offers.header[0] && offers.header[0].opt_vol}</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Estr.</h1>
								<p>{offers.header[0] && offers.header[0].strat_vol}</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Act.</h1>
								<p>{offers.header[0] && offers.header[0].curr_price}</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Opt</h1>

								<p>{offers.header[0] && offers.header[0].opt_price}</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Estr.</h1>
								<p>{offers.header[0] && offers.header[0].strat_price}</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Act.</h1>
								<p >{offers.header[0] && offers.header[0].curr_ro_mm}</p> { /* className='orange' */ }
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Opt</h1>
								<p  >{offers.header[0] && offers.header[0].opt_ro_mm}</p>{ /*className='orange' */ }
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Estr.</h1>
								<p>{offers.header[0] && offers.header[0].strat_ro_mm}</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Estr. - Act.</h1>
								<p>{offers.header[0] && offers.header[0].strat_act_benefit}</p>
							</div>
						</th>
						<th>
							<div className='head_box'>
								<h1>Opt. - act.</h1>
								<p>{offers.header[0] && offers.header[0].opt_act_benefit}</p>
							</div>
						</th>
						<th></th>
						<th style={{width: '80px'}}>
							<div
								className='head_box wrap_input'
								style={{transform: 'translateX(5px)'}}
							>
								<input
									type='text'
									value={String(mgcad).replace(".", ",")}
									onChange={(e) => {
										if(e.target.value.replace(" ","") === "") return
										setmgcad(e.target.value)
									}}
									onKeyUp={(e) => {
										if (e.key === 'Enter') {
											console.log('Enter')
											setmgcad(parseFloat(e.target.value.replace(",",".")).toFixed(1))
											setMgPvp(parseFloat(e.target.value.replace(",",".")).toFixed(1))
										}
									}}
									onBlur={(e) => {
										if(e.target.value.replace(" ","") === "") return
										setmgcad(parseFloat(e.target.value.replace(",",".")).toFixed(1))
										setMgPvp(parseFloat(e.target.value.replace(",",".")).toFixed(1))
									}}
								/>
							</div>
						</th>
					</tr>
				</thead>
			</Table>
			<Table>
				<tbody>
					<tr className='special_body_row special_body_row_new'>
						<td colSpan={7}>
							<div className='display_flex  gray'>
								<p>Producto</p>
							</div>
						</td>
						<td colSpan={4}>
							<div className='display_flex  gray'>
								<p>Volumen</p>
							</div>
						</td>
						<td colSpan={4}>
							<div className='display_flex  gray'>
								<p>Precio</p>
							</div>
						</td>
						<td colSpan={3}>
							<div className='display_flex  gray'>
								<p>Margen</p>
							</div>
						</td>
						<td colSpan={5}>
							<div className='display_flex  gray'>
								<p>Resultado Esperado</p>
							</div>
						</td>
						<td colSpan={2}>
							<div className='display_flex  gray'>
								<p>.</p>
							</div>
						</td>
						<td></td>
					</tr>
					<tr className='special_body_row special_body_row_new_one'>
						<td>
							<div className='display_flex  gray'>
								<p>Estr.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Código</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>Descr.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>Subfa.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Marca</p>
							</div>
						</td>
						<td>
							<div className='display_flex  gray'>
								<p>Oficinas</p>
							</div>
						</td>
						<td className='border-gray-custom'>
							<div className='display_flex  center_justify gray'>
								<p>Act.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray'>
								<p>Act.</p>
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray'>
								<p>Opt.</p>
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Estr.</p>
							</div>
						</td>
						<td className='border-gray-custom'>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Estr. + OI</p>
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Act.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Opt.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Estr.</p>
							</div>
						</td>
						<td className='border-gray-custom'>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Estr/Act</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Act.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Opt.</p>
							</div>
						</td>
						<td className='border-gray-custom'>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Estr.</p>
							</div>
						</td>

						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>RO Estr.</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Uplift</p>
							</div>
						</td>
						<td>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>RO Incr</p>
							</div>
						</td>
						<td >
							<div className='display_flex  center_justify gray blue_bg'>
								<p>PVP Sug.</p>
							</div>
						</td>

						<td className='border-gray-custom'>
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Elast.</p>
							</div>
						</td>
						<td >
							<div className='display_flex  center_justify gray blue_bg'>
								<p>Var Pro</p>
							</div>
						</td>
						
					</tr>
					{ /* Uno por cada línea... , ingresar data filtrada por linea*/ }
				{offers && familias.map((familia) => {
					// Filtros de búsqueda Linea
					if(FirstFilter !== "Todas" && FirstFilter !== familia) return (false)

					const ofertasFiltradas = offers.data_rows.filter((offer) => offer.brand === familia);
					const groupedRows = offers.grouped_rows.find((group) => group[familia]);
					return (
					<ProductTableRow
						key={idOferta+"_"+familia}
						setdeletestate={setdeletestate}
						setModeloPopupState={setModeloPopupState}
						idOferta={idOferta}
						familia={familia}
						offers={ofertasFiltradas}
						groupedRows = {groupedRows}
						setProduct={setProduct}
						SecondFilter={SecondFilter} // Se envía segundo filtro
						setRowChange = {setRowChange}
						setDeleteRow = {setDeleteRow}
						mgPvp = {mgPvp}
						
					/>)
					}
				)}
				</tbody>
			</Table>
			<br></br><br></br>
		</div>
	)
}
