import React, {useState} from 'react'
import '../../assets/css/newPFOffer/IngresoProductTable.css'
import forwardArrow from '../../assets/img/forwardArrow.svg'
import greenarrow from '../../assets/img/newPFOffer/green-arrow.svg'
import {ProductTableFamiliaInner} from './ProductTableFamiliaInner'

export const ProductTableFamilia = ({
	// setProductTableFamiliaInnerState,
	// ProductTableFamiliaInnerState,
	setdeletestate, setModeloPopupState, subfamilia, offers, data, setProduct, SecondFilter, setRowChange
	, setDeleteRow, mgPvp
}) => {
	
	const [ProductTableFamiliaInnerState, setProductTableFamiliaInnerState] = useState(true)

	console.log("DATA GROUPED FILTRADAS POR SUBFAMILIA::: ")
	console.log(data)
	return (
		<>
			<tr
				className={`Linea1 ${
					ProductTableFamiliaInnerState && 'rotate_img'
				} subcategory`}
				onClick={(e) => {
					setProductTableFamiliaInnerState(!ProductTableFamiliaInnerState)
				}}
			>
				<td>
					<div className='display_flex familiap  center_justify gray blue_bg special_p'>
						<p>{subfamilia}</p>
					</div>
				</td>
				<td colSpan={5} className='border-gray-custom-blue'>
					<div
						className='display_flex  center_justify gray blue_bg special_p'
						style={{justifyContent: 'flex-start', paddingLeft: 25}}
					>
						<img src={forwardArrow} alt='' />
					</div>
				</td>

				<td>
					<div className='display_flex  gray'>
						
					</div>
				</td>
				<td>
					<div className='display_flex  gray'>
					<p> {data.curr_vol}</p>
						
					</div>
				</td>
				<td>
					<div className='display_flex  gray'>
					<p> {data.opt_vol}</p>
						
					</div>
				</td>
				<td className='border-gray-custom-blue'>
					<div className='display_flex   gray'>
						{ /* <img src={greenarrow} alt='' style={{transform: `rotate(0)`}} /> */ }
						
						<p> {data.strat_vol}</p>
					</div>
				</td>
				<td colSpan={7} className='border-gray-custom-blue'></td>
				<td>
					<div className='display_flex   gray'>
						{ /* <img src={greenarrow} alt='' style={{transform: `rotate(0)`}} /> */ }
						<p>{data.strat_ro_pct}</p>
					</div>
				</td>
				<td>
					<div className='display_flex   gray'>
						{ /* <img src={greenarrow} alt='' style={{transform: `rotate(0)`}} /> */ }
						<p>{data.incr_ro}</p>
					</div>
				</td>
				<td>
					<div className='display_flex   gray'>
						{ /* <img src={greenarrow} alt='' style={{transform: `rotate(0)`}} /> */ }
						<p>-</p>
					</div>
				</td>
				<td className='border-gray-custom-blue'>
					<div className='display_flex  gray'>
						<p>{data.elasticity}</p>
					</div>
				</td>
				<td colSpan={3}></td>
			</tr>

			{ProductTableFamiliaInnerState && // Fila principal de producto, por cada producto se ponen las filas informativas filtradas que correspondan
				offers.map((offer, index) => {
					// Se aplica segundo filtro
					if(SecondFilter !== "Todas" && SecondFilter !== offer.short_brand) return false;
					return (
					<>	{!mgPvp &&
						<ProductTableFamiliaInner
							// ProductTableInnerState={ProductTableInnerState}
							// setProductTableInnerState={setProductTableInnerState}
							key={offer.id}
							setdeletestate={setdeletestate}
							setModeloPopupState={setModeloPopupState}
							offer = {offer}
							setProduct = {setProduct}
							setRowChange = {setRowChange}
							setDeleteRow = {setDeleteRow}
						/>
						}
					</>
					)
				}
			)}
			
		</>
	)
}
