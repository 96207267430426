import {useEffect, useState} from 'react'
import {ToolTipCustom} from '../ToolTipCustom'

import Si from '../../assets/img/Si.svg'
import No from '../../assets/img/No.svg'
import orangeDown from '../../assets/img/orange_down.svg'
import tableProductsMinus from '../../assets/img/minus-table-products.svg'
import tableProductsTick from '../../assets/img/tick-table-products.svg'
import Recomendacion2 from '../../assets/img/Recomendacion2.svg'
import Volumen from '../../assets/img/Volumen.svg'
import Volumen_Rentabilidad from '../../assets/img/Volumen_Rentabilidad.svg'
import Recomendacion from '../../assets/img/Recomendacion.svg'
import '../../assets/css/newPFOffer/ingreso-product.css'

export const PureTableProductsRow = ({name, dataProduct, style}) => {
	var display_ = 'table-row'
	if(!style){
		display_ = 'none'
	}
	return (
		<tr className={'VienesasRowGray'}
		style={{display: display_}}
		>
			<td>
				<div className='display_flex gray ' style={{position: 'relative'}}>
					{/*model, si es 1 es el check y si es 0 es la x*/}
					{dataProduct.model === 1 ? <img src={Si} alt='' /> : <img src={No} alt='' />}
					{/* si es 0 entonces ciruclo, */}
					{(dataProduct.tltp_strategy_name === "" || !dataProduct.tltp_strategy_name) 
					&& <img src={Recomendacion} alt='' />}
					{dataProduct.tltp_strategy_name === "Rentabilidad" && <img src={Recomendacion2} alt='' />}
					{dataProduct.tltp_strategy_name === "Volumen" && <img src={Volumen} alt='' />}
					{dataProduct.tltp_strategy_name === "Optimizacion" && (
						<img src={Volumen_Rentabilidad} alt='' />
					)}
					<p className='blue-hover'>
						<svg
							width='8'
							height='18'
							viewBox='0 0 8 18'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<g clipPath='url(#clip0_680_360734)'>
								<rect
									x='8'
									y='0.5'
									width='12'
									height='12'
									rx='2'
									transform='rotate(45 8 0.5)'
									fill='#36A7F5'
								/>
							</g>
							<defs>
								<clipPath id='clip0_680_360734'>
									<rect
										width='17'
										height='8'
										fill='white'
										transform='matrix(0 1 -1 0 8 0.5)'
									/>
								</clipPath>
							</defs>
						</svg>
						{dataProduct.tltp_strategy_name}
					</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray'>
					<a
						href='#'
						onClick={(e) => {
							e.preventDefault()
						}}
					>
						{dataProduct.product_code && dataProduct.product_code}
					</a>
				</div>
			</td>
			<td>
				<div className='display_flex  gray description-area-products'
				> { /* Agragar la clase al lado de gray: description-area-products*/ }
					<p style={{fontSize: "11px"}}>
						{dataProduct.description && dataProduct.description}
					</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray description-area-products'>
					<p>{dataProduct.subcategory_name && dataProduct.subcategory_name}</p>
				</div>
			</td>
			<td>
				<div className='display_flex  gray description-area-products'>
					<p>{dataProduct.subfamily && dataProduct.subfamily.toUpperCase()}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray description-area-products'>
					<p>{dataProduct.short_brand}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray description-area-products'>
					<p>${dataProduct.strategic_price}</p>
				</div>
			</td>

			<td>
				<div className='display_flex gray description-area-products'>
					<p>${String(dataProduct.strategic_margin).split(".")[0]}</p>
				</div>
			</td>

			<td>
				<div className='display_flex gray description-area-products'>
					<p>${dataProduct.strategic_ro_price_kg}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray description-area-products'>
					<p>{dataProduct.strategic_volume}</p>
				</div>
			</td>
			<td>
				<div className='display_flex gray description-area-products'>
					{dataProduct.on_catalog === 1 ? <img src={tableProductsTick} alt='' />
					: <img src={tableProductsMinus} alt='' />}
				</div>
			</td>
			<td>
				<div className='display_flex gray description-area-products'>
					{dataProduct.on_offer === 1 ? 
					<img src={tableProductsTick} alt='' />
					: <img src={tableProductsMinus} alt='' />}
					
				</div>
			</td>

			<td>
				<div className='display_flex gray container description-area-products'>
					<input type='checkbox' id={`id_`+dataProduct.offer_product_id} 
					value={dataProduct.offer_product_id}
					data-custom={!style ? 0 : 1}
					data-product={JSON.stringify(dataProduct)}
					/>
					<label htmlFor={`id_`+dataProduct.offer_product_id}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 24 24'
							fill='#fff'
							className='w-6 h-6'
						>
							<path
								fillRule='evenodd'
								d='M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z'
								clipRule='evenodd'
							/>
						</svg>
					</label>
				</div>
			</td>
		</tr>
	)
}
